import {create} from 'zustand';
import {type SetStateAction, useEffect} from 'react';
import {ConsentAllStatus, type PrivacySettings} from '@shopify/dux';

import {
  ApprovedCookie,
  ComplianceZone,
  CookieNoticeBannerVariant,
} from '@/enums';
import {getCookie, SameSite, setCookie} from '@/utils/clientCookie';
import {FEATURE_FLAGS} from '@/constants';

import {base64Encode, base64Decode} from '../utils/base64';

import {usePrivacyTracking} from './useDuxTracking';
import useDataLayer from './useDataLayer';

export const API_VERSION = '1.0.1';

interface InitialState {
  url: string;
  rootDomain: string;
  complianceZone: string;
  countryCode: string;
  shouldShowCookieNotice?: boolean;
  variant?: CookieNoticeBannerVariant;
  apiVersion?: string;
}

interface StateActions {
  updateState: (props: Partial<Store>) => Store;
  persistPassiveConsent: () => void;
  persistPrivacySignal: (hasConsentedAll: boolean) => void;
  updateShowCookieNotice: (shouldShowCookieNotice: boolean) => void;
  getPublicState: () => PublicStore;
}

interface PublicStore {
  isActiveConsentVariant?: boolean;
  isConsentRequired?: boolean;
  hasMadeChoice?: boolean;
  hasConsentedAll?: boolean;
  onChangeCallback?: (ops: PublicStore) => void;
  updateOnChangeCallback?: (fn: any) => void;
}

interface Store extends InitialState, PublicStore, StateActions {
  handleTracking?: (props: PrivacySettings) => void;
  privacySignal?: string;
  privacySettings?: PrivacySettings;
}

const getIsConsentRequired = (complianceZone: ComplianceZone) => {
  return complianceZone === ComplianceZone.Gdpr;
};
const getIsActiveConsentVariant = (variant?: CookieNoticeBannerVariant) => {
  return variant === CookieNoticeBannerVariant.ActiveConsent;
};

export const useBannerVariant = () => {
  const {complianceZone} = useDataLayer();
  return complianceZone === ComplianceZone.Gdpr
    ? CookieNoticeBannerVariant.ActiveConsent
    : undefined;
};

export const useFooterManageConsent = () => {
  const {complianceZone} = useDataLayer();
  const variant = useBannerVariant();
  const isConsentRequired = getIsConsentRequired(
    complianceZone as ComplianceZone,
  );
  const isActiveConsentVariant = getIsActiveConsentVariant(variant);
  return isConsentRequired && isActiveConsentVariant;
};

const cookieNoticeBannerState = create(
  (set: SetStateAction<any>, get: () => Partial<Store> & StateActions) => {
    const updateState = (props: Partial<Store>) => {
      return set(props);
    };

    const updateOnChangeCallback = (onChangeCallback: any) => {
      set({onChangeCallback});
    };

    const persistPrivacySignal = (hasConsentedAll: boolean) => {
      const {
        apiVersion = API_VERSION,
        hasConsentedAll: previousHasConsentedAll,
        countryCode,
        complianceZone,
        rootDomain,
        url,
        handleTracking,
        onChangeCallback,
      } = get() as Store;

      const privacySettings: PrivacySettings = {
        consentedAll: hasConsentedAll
          ? ConsentAllStatus.Accepted
          : ConsentAllStatus.Rejected,
        countryCode,
        complianceZone,
        rootDomain,
        date: String(Date.now()),
        url,
        apiVersion,
      };

      const privacySignal = base64Encode(JSON.stringify(privacySettings));
      // persist consent state to cookie
      setCookie(ApprovedCookie.PrivacySignal, privacySignal, {
        ...(rootDomain ? {domain: rootDomain} : {}),
        maxage: 365 * 24 * 60 * 60 * 1000,
        path: '/',
        secure: true,
        samesite: SameSite.Lax,
      });

      updateState({
        hasMadeChoice: true,
        hasConsentedAll,
        privacySignal,
        privacySettings,
        shouldShowCookieNotice: false,
      });

      handleTracking && handleTracking(privacySettings);

      if (
        previousHasConsentedAll &&
        previousHasConsentedAll !== hasConsentedAll
      ) {
        // reload page if user has previously consented and now has declined
        setTimeout(() => window.location.reload(), 500);
      }

      if (onChangeCallback)
        onChangeCallback({
          hasConsentedAll,
        });
    };

    const persistPassiveConsent = () => {
      // persist consent state to cookie
      const {rootDomain} = get();
      setCookie(
        ApprovedCookie.CookieNoticeAcknolwedged,
        // Only supports an "Accept" option
        '1',
        {
          ...(rootDomain ? {domain: rootDomain} : {}),
          maxage: 365 * 24 * 60 * 60 * 1000,
          path: '/',
          secure: true,
          samesite: SameSite.Lax,
        },
      );

      // set local state
      updateState({
        hasConsentedAll: true,
        hasMadeChoice: true,
        shouldShowCookieNotice: false,
      });
    };

    const getPublicState = () => {
      const {
        isActiveConsentVariant,
        isConsentRequired,
        hasMadeChoice,
        hasConsentedAll,
        updateOnChangeCallback: cb,
      } = get();

      const publicStore: PublicStore = {
        isActiveConsentVariant,
        isConsentRequired,
        hasMadeChoice,
        hasConsentedAll,
        updateOnChangeCallback: cb,
      };
      return publicStore;
    };

    const updateShowCookieNotice = (shouldShowCookieNotice: boolean) => {
      updateState({
        shouldShowCookieNotice,
      });
    };

    return {
      complianceZone: ComplianceZone.None,
      variant: CookieNoticeBannerVariant.PassiveConsent,
      shouldShowCookieNotice: false,
      hasMadeChoice: false,
      hasConsentedAll: false,
      isActiveConsentVariant: false,
      updateState,
      updateOnChangeCallback,
      // (legacy) passive consent state
      persistPassiveConsent,
      // active consent state
      persistPrivacySignal,
      getPublicState,
      updateShowCookieNotice,
    };
  },
);

export const useCookieNoticeBanner = (initialState?: InitialState) => {
  const store = cookieNoticeBannerState();
  const handleTracking = usePrivacyTracking();
  const serverVariant = useBannerVariant();

  // TODO: Refactor this to make it simpler
  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!initialState) return;
    const {countryCode, apiVersion = API_VERSION} = initialState;
    const url = new URL(location.href, 'https://www.shopify.com');
    const variant: CookieNoticeBannerVariant =
      FEATURE_FLAGS.DEBUG_COMPLIANCE_ZONE_VARIANT(url) ||
      serverVariant ||
      CookieNoticeBannerVariant.PassiveConsent;

    // automatically set compliance zone to gdpr if variant flag exists, otherwise use the compliance zone passed in
    const complianceZone = FEATURE_FLAGS.DEBUG_COMPLIANCE_ZONE_VARIANT(url)
      ? ComplianceZone.Gdpr
      : FEATURE_FLAGS.DEBUG_COMPLIANCE_ZONE(url) || initialState.complianceZone;

    const rootDomain =
      initialState.rootDomain &&
      location.hostname.includes(initialState.rootDomain)
        ? initialState.rootDomain
        : '';

    const {updateState, persistPrivacySignal} = store;
    const isConsentRequired = getIsConsentRequired(
      complianceZone as ComplianceZone,
    );
    const isActiveConsentVariant = getIsActiveConsentVariant(variant);

    let hasMadeChoice = false;
    let hasConsentedAll = false;
    let privacySettings;
    let privacySignal;
    let shouldResetPrivacySignal = false;

    const clearPrivacySignal = () => {
      privacySettings = undefined;
      privacySignal = undefined;
      setCookie(ApprovedCookie.PrivacySignal, '', {
        ...(rootDomain ? {domain: rootDomain} : {}),
        maxage: 0,
        path: '/',
        secure: true,
        samesite: SameSite.Lax,
      });
    };

    if (isActiveConsentVariant) {
      // Active Consent Model
      privacySignal = getCookie(ApprovedCookie.PrivacySignal);
      if (privacySignal) {
        try {
          privacySettings = JSON.parse(base64Decode(privacySignal));
          const {consentedAll, apiVersion: persistedApiVersion = ''} =
            privacySettings as PrivacySettings;

          const [pMajor, pMinor, pHotfix] = persistedApiVersion.split('.');
          const [major, minor, hotfix] = apiVersion.split('.');
          let isValid = Boolean(consentedAll);

          if (persistedApiVersion !== apiVersion) {
            if (major !== pMajor || minor !== pMinor) {
              isValid = false;
            } else if (hotfix !== pHotfix) {
              shouldResetPrivacySignal = true;
            }
          }

          if (isValid) {
            hasMadeChoice = true;
            hasConsentedAll = consentedAll === ConsentAllStatus.Accepted;
          } else {
            clearPrivacySignal();
          }
        } catch (err) {
          console.error(`Invalid privacy settings, ${err}`);
          // if the cookie is malformed, delete it
          clearPrivacySignal();
        }
      }
    } else {
      // Passive Consent Model
      const persistedPassiveConsent =
        getCookie(ApprovedCookie.CookieNoticeAcknolwedged) || '';
      if (persistedPassiveConsent === ConsentAllStatus.Accepted) {
        hasConsentedAll = true;
        hasMadeChoice = true;
      }
    }

    const shouldShowCookieNotice =
      initialState.shouldShowCookieNotice ||
      (isConsentRequired && !hasMadeChoice);

    updateState({
      rootDomain,
      complianceZone,
      url: url.href,
      variant,
      countryCode,
      apiVersion,

      hasMadeChoice,
      shouldShowCookieNotice,

      isConsentRequired,
      isActiveConsentVariant,
      hasConsentedAll,

      privacySignal,
      privacySettings,

      handleTracking,
    });

    // provides consent api outside of react
    if (typeof window !== 'undefined') {
      window.cmp = store.getPublicState;
    }

    // automatically re-obtain the privacy signal if the api hotfix version has changed
    if (shouldResetPrivacySignal) {
      persistPrivacySignal(hasConsentedAll);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTracking]);

  return store;
};
