import {useEffect, useState} from 'react';

import {checkMobile, getCookie} from '@/utils/utils';
import {useLoadScript} from '@/hooks/useLoadScript';
import {FEATURE_FLAGS, GTM_ID_DEFAULT} from '@/constants';
import {useCookieNoticeBanner} from '@/hooks/useCookieNoticeBanner';
import {ApprovedCookie} from '@/enums';
import env from '@/utils/env';
import {useSiteData} from '@/hooks/useSiteData';
import useHandle from '@/hooks/useHandle';
import useDataLayer from '@/hooks/useDataLayer';

function RenderGTM() {
  const {site, canonicalUrl} = useSiteData();
  const dataLayer = useDataLayer();
  const [scriptSrc, setScriptSrc] = useState('');
  const {gtmAccountId} = useHandle();
  const {hasConsentedAll, isConsentRequired, isActiveConsentVariant} =
    useCookieNoticeBanner();

  useLoadScript(scriptSrc);

  useEffect(() => {
    if (
      !isConsentRequired ||
      !isActiveConsentVariant ||
      (hasConsentedAll && isActiveConsentVariant)
    ) {
      setScriptSrc(
        `https://www.googletagmanager.com/gtm.js?id=${
          gtmAccountId || GTM_ID_DEFAULT
        }&dl=dataLayer`,
      );
    }
  }, [
    setScriptSrc,
    isConsentRequired,
    hasConsentedAll,
    isActiveConsentVariant,
    gtmAccountId,
  ]);

  useEffect(() => {
    const currentURL = new URL(window.location.href, 'https://www.shopify.com');
    const siteData = site || {};

    /* eslint-disable camelcase */
    const gtmDataLayer = {
      affiliate: dataLayer.affiliate,
      service: env('SHOPIFY_APP_SERVICE'),
      blog_id: dataLayer.blogId,
      blog_category: dataLayer.blogCategory,
      canonical_url: canonicalUrl || '',
      compliance_zone: dataLayer.complianceZone,
      country_code: dataLayer.countryCode || '',
      continent_code: dataLayer.continentCode || '',
      currency_code: 'USD',
      display_cookies_notice: dataLayer.displayCookiesNotice,
      environment: dataLayer.environment,
      experiment_variation_id: dataLayer.experimentVariationId,
      http_code: dataLayer.httpCode,
      is_new_user: '',
      itcat: currentURL.searchParams.get('itcat'),
      itterm: currentURL.searchParams.get('itterm'),
      language: dataLayer.language,
      last_shop_domain: getCookie(ApprovedCookie.LastShop),
      last_shop_id: '',
      opt_in: '',
      page_category: dataLayer.pageCategory,
      page_group: dataLayer.pageGroup,
      page_language:
        siteData.locale?.substring(0, siteData.locale.indexOf('-')) || 'en',
      page_name: currentURL.pathname,
      page_subtopic: dataLayer.pageSubtopic,
      page_title: document.title,
      page_topic: dataLayer.pageTopic,
      page_url: currentURL.toString(),
      page_variation: currentURL.searchParams.get('dest'),
      project: 'Shopify',
      site_country_code: siteData.countryCode,
      site_domain: siteData.domain,
      site_display_format: checkMobile(navigator.userAgent),
      event: 'page_view',
      user_language: navigator.language,
      user_token: getCookie(ApprovedCookie.MultiTrackToken),
      utm_campaign: currentURL.searchParams.get('utm_campaign'),
      utm_content: currentURL.searchParams.get('utm_content'),
      utm_medium: currentURL.searchParams.get('utm_medium'),
      utm_source: currentURL.searchParams.get('utm_source'),
      utm_term: currentURL.searchParams.get('utm_term'),
      path_prefix: siteData.pathPrefix,
    };

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(gtmDataLayer);
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    /* eslint-enable camelcase */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default function GoogleTagManager() {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (
      !FEATURE_FLAGS.DEBUG_DISABLE_ANALYTICS &&
      !FEATURE_FLAGS.DEBUG_DISABLE_GTM &&
      !(window as any).E2E
    ) {
      setShouldRender(true);
    }
  }, []);

  return shouldRender ? <RenderGTM /> : null;
}
