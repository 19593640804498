import {VALID_GCLID_PARAM, VALID_UTM_PARAMS} from '@shopify/dux';
import {parse} from 'query-string';

import {isValidUrl} from '@/utils/url/url';

export function addGclidFromRequestToUrl(
  baseUrl: string,
  requestQueryString: string | null,
) {
  if (!requestQueryString) return baseUrl;

  const params = parse(requestQueryString);
  const gclid = params[VALID_GCLID_PARAM];
  if (!gclid) return baseUrl;

  const url = new URL(baseUrl, 'https://www.shopify.com');
  url.searchParams.set(VALID_GCLID_PARAM, String(gclid));
  return url.toString();
}

export function addUtmParamsFromRequestToUrl(
  baseUrl: string,
  requestQueryString: string | null,
) {
  if (!requestQueryString) return baseUrl;

  const url = new URL(baseUrl, 'https://www.shopify.com');
  const params = url.searchParams;

  // Get utmParam query string and remove the ? prefix
  const utmParams = extractUTMParams(requestQueryString).substring(1);
  // If there are no UTM params on the request url return the given admin url
  if (utmParams === '') return url.toString();
  // Remove any UTM params on the admin url. UTM params act as a
  // compound primary key so we want to override any existing UTM params
  // with the ones from the request url so that we can accurately
  // attribute the traffic to the admin to a particular source
  for (const key of url.searchParams.keys()) {
    if (VALID_UTM_PARAMS.includes(key)) {
      url.searchParams.delete(key);
    }
  }
  // Add UTM params from the request url to the admin url.
  utmParams.split('&').forEach((utmParam: string) => {
    const utmParamTuple = utmParam.split('=');
    params.append(utmParamTuple[0], utmParamTuple[1]);
  });

  return url.toString();
}

export function extractGclid(queryString: string) {
  const params = parse(queryString);
  return params[VALID_GCLID_PARAM] ? String(params[VALID_GCLID_PARAM]) : '';
}

export function extractUTMParams(queryString: string) {
  const params = parse(queryString);
  const validParams = VALID_UTM_PARAMS.reduce<string[]>((acc, key) => {
    if (params[key])
      acc.push(`${key}=${encodeURIComponent(String(params[key]))}`);
    return acc;
  }, []);

  return validParams.length > 0 ? `?${validParams.join('&')}` : '';
}

/**
 * Appends the utms to the signup url and merge the current page query string parameters
 *
 * @param signupUrl URL object or URL string
 * The signup url to append the utms to
 * @param utms Record<string, string | null | undefined>
 * The utms to append to the signup path
 * @returns string
 * The signup path with the utms appended or original string if not a valid URL
 **/
export const addUtmParamsToSignupUrl = <
  T extends Record<string, string[] | string | null | undefined>,
>(
  signupUrl: URL | string,
  utms: T,
): string => {
  const urlString =
    typeof signupUrl === 'string' ? signupUrl : signupUrl.toString();

  if (typeof signupUrl === 'string' && !isValidUrl(urlString)) {
    return urlString;
  }

  const signupTrackingUrl = new URL(urlString, 'https://www.shopify.com');
  const signupSearchParams = new URLSearchParams(signupTrackingUrl.search);

  // Copy over the utms
  Object.entries(utms).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => {
        signupSearchParams.append(`${key}[]`, v);
      });
    } else {
      signupSearchParams.set(key, value);
    }
  });

  signupTrackingUrl.search = signupSearchParams.toString();
  return signupTrackingUrl.toString();
};
