import {ApprovedCookie, UrlParam} from '@/enums';
import {getCookie} from '@/utils/clientCookie';

import type {SignupMetadata} from './types';
import {
  AFFILIATE_TRACKING_PARAMS,
  DEFAULT_SIGNUP_METADATA,
  SIGNUP_BASE_URL,
  SIGNUP_REF_AND_PROMO_PARAMS,
} from './constants';

/**
 * Updates by reference the `targetUrlParams` by copying the parameters
 * listed in `urlParams` from the `sourceUrlParams` if they exist.
 * @param urlParams A list of parameters to extract from the `sourceUrlParams`
 * @param targetUrlParams UrlSearchParams to copy parameters to
 * @param sourceUrlParams URLSearchParams to extract parameters from
 * @returns void
 */
function setParams(
  urlParams: UrlParam[],
  targetUrlParams: URLSearchParams,
  sourceUrlParams: URLSearchParams,
) {
  for (const parameterName of urlParams) {
    const parameterValue = sourceUrlParams.get(parameterName);

    if (parameterValue) {
      targetUrlParams.set(parameterName, parameterValue);
    }
  }
}

/**
 * Builds a Signup `URL` from the server side metadata by adding client side information as well.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL
 * @see https://vault.shopify.io/pages/18975-Guidelines-for-signup-entry-points-and-calls-to-action
 * @param signupMetadata Optional `url`, `email` and `pageViewToken` to build the signup URL from.
 * @returns New `URL` pointing to the Signup funnel with proper client-side metadata.
 */
export function buildSignupURL(
  signupMetadata: SignupMetadata = DEFAULT_SIGNUP_METADATA,
): URL {
  const metadata = {...DEFAULT_SIGNUP_METADATA, ...signupMetadata};
  const {url = SIGNUP_BASE_URL, email, pageViewToken} = metadata;

  const signupUrl = new URL(url, 'https://www.shopify.com');
  const signupParams = signupUrl.searchParams;

  if (typeof window === 'undefined') {
    return signupUrl;
  }

  const currentPageParams = new URL(
    window.location.href,
    'https://www.shopify.com',
  ).searchParams;

  // Essential cookies for tracking
  const multitrackTokenCookie = getCookie(ApprovedCookie.MultiTrackToken);
  if (multitrackTokenCookie) {
    signupParams.set(UrlParam.MultiTrackToken, multitrackTokenCookie);
  }

  const sessionTokenCookie = getCookie(ApprovedCookie.SessionToken);
  if (sessionTokenCookie) {
    signupParams.set(UrlParam.SessionToken, sessionTokenCookie);
  }

  if (pageViewToken) {
    signupParams.set(UrlParam.PageViewToken, pageViewToken);
  }

  const signupPageUrl = new URL(
    signupParams.get(UrlParam.SignupPage) || window.location.href,
    'https://www.shopify.com',
  );

  // Affiliate partners tracking
  setParams(
    AFFILIATE_TRACKING_PARAMS,
    signupPageUrl.searchParams,
    currentPageParams,
  );

  signupParams.set(UrlParam.SignupPage, signupPageUrl.href);

  // Encoded email for Id creation
  if (email) {
    const loginValue = window.btoa(encodeURIComponent(email));
    signupParams.set(UrlParam.Login, loginValue);
  }

  // Append Ref and Promo Code params
  setParams(SIGNUP_REF_AND_PROMO_PARAMS, signupParams, currentPageParams);

  return signupUrl;
}
