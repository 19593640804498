import type {Mode} from '@shopify/dux';
import {Dux as DuxClient} from '@shopify/dux';

import type {Site, Region} from '@data/types';
import useHandle from '@/hooks/useHandle';
import useDataLayer from '@/hooks/useDataLayer';
import env from '@/utils/env';
import {DUX_ROUTE} from '@/constants';

import SignupClickHandler from '../SignupClickHandler/SignupClickHandler';
import UtmClickHandler from '../UtmClickHandler/UtmClickHandler';
import DriftBot from '../Driftbot/Driftbot';
import GoogleTagManager from '../GoogleTagManager/GoogleTagManager';

interface AnalyticsDataProps {
  handle: string;
  rootDomain: string;
  url: string;
  site: Site;
  regions: Region[];
}

interface AnalyticsProps {
  data: AnalyticsDataProps;
}

export default function DataLayer({data}: AnalyticsProps) {
  const dataLayer = useDataLayer();
  const {driftBot, enableGtm} = useHandle();
  const {rootDomain, url, site, regions, handle} = data || {};

  if (!url) {
    return null;
  }

  const currentUrl = new URL(url, 'https://www.shopify.com');

  return (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <DuxClient
          service={env('SHOPIFY_APP_SERVICE')}
          mode={env('SHOPIFY_APP_ENV') as Mode}
          enableGtm={true}
          enableGtmLoader={false}
          enableActiveConsent={false}
          locale={site.locale}
          emitTrekkiePageViewEvent={true}
          eventHandlerEndpoint={DUX_ROUTE}
          countryCode={dataLayer.countryCode}
          regionCode={dataLayer.regionCode}
          metadata={{
            pageId: handle,
            handle,
            regions,
            page: dataLayer,
            site,
            rootDomain,
            extraData: {
              currencyCode: site.currencyCode,
            },
          }}
        />
      )}
      {enableGtm && <GoogleTagManager />}
      <SignupClickHandler />
      <UtmClickHandler requestQueryString={currentUrl.search} />
      {driftBot && driftBot({site}) && <DriftBot />}
    </>
  );
}
